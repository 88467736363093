<template>
    <div class="qr-custom-editor">
        <div class="qr-custom-tool">
            <div class="tool-tab" v-for="tool in tools" :key="tool.tab">
                <div class="tool-tab-title">{{ tool.tabTitle }}</div>
                <ul class="tool-list">
                    <li v-for="(field, index) in tool.fields" :key="index">
                        <span class="field-label">
                            {{ field.label }}
                        </span>
                        <input type="text" placeholder="Default label" class="field field-custom" v-model="field.custom">
                        <input type="text" placeholder="Default value" class="field field-value" v-model="field.value">
                        <a class="btn btn-w-shadow btn-standart icon-rigth" 
                            :class="{'btn-primary': field.added}" 
                            @click.prevent="toggleField(field)"
                        >
                            {{ field.added ? 'Included' : 'Include' }}
                        </a>
                    </li>
                </ul>
            </div>
        </div>
        <div class="qr-custom-viewer">
            <div class="viewer-header">
                #viewer-header
            </div>
            <div class="viewer-body">
                <div class="viewer-tab" v-for="tab in includedTools" :key="tab.tabTitle">
                    <div class="tab-title">{{ tab.tabTitle }}</div>
                    <div class="tab-media">#media</div>
                    <ul class="field-list">
                        <li v-for="(field, index) in tab.fields" :key="index">
                            <span class="field-label">{{ field.custom || field.label }}</span>
                            <span class="field-value">{{ field.value || getFieldValue(tab, field) }}</span>
                        </li>
                    </ul>

                </div>
            </div>
            <div class="viewer-footer">

            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: ['product'],
    data() {
        return {
            tools: [
                {
                    tab: 'product',
                    tabTitle: 'Product',
                    fields: [
                        { label: 'Title', field: 'title', custom: '', value: '', added: false },
                        { label: 'Quantity/Unit', fields: ['quantity', 'units'],  custom: '', join: ' / ', value: '', added: false},
                        { label: 'Manufacturer', field: 'company_name', custom: '', value: '', added: false },
                        { label: 'Batch ID', field: 'batch_id', custom: '', value: '', added: false },
                        { label: 'Description', field: 'description', custom: '', value: '', added: false },
                        { label: 'Expiry Date', field: 'expiry_date', custom: '', value: '', added: false },
                    ]
                }
            ],
        }
    },
    computed: {
        includedTools() {
            return this.tools.map(tool => {
                return {...tool, fields: tool.fields.filter(field => field.added)}
            })
        }
    },
    methods: {
        toggleField(field) {
            field.added = !field.added
        },
        getFieldValue(tab, field) {
            // product | company | history
            return 'TODO: Real value here'
        }
    }
}
</script>

<style scoped>
.qr-custom-editor {
    display: flex;
    justify-content: space-between;
    width: 100%;
}
.qr-custom-tool {
    width: 50%;
    padding: 20px 0;
}
.qr-custom-viewer {
    width: 50%;
    padding: 20px;
}
.tool-tab-title {
    padding: 10px 0;
    border-bottom: 1px solid #dde2e4;
    margin-bottom: 15px;
}
.tool-list li {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    padding: 5px 0;
    margin: 5px;
}
.tool-list li .field-label {
    width: 100%;
}
.tool-list li .field {
    border: 1px solid #dde2e4;
    padding: 7px;
    width: calc(50% - 60px);
}
.tool-list a {
    width: 100px;
}
</style>