<template>
    <div class="wrapper_main">
        <main class="content">
            <div class="content__header sub-page">
                <div class="content__header-left">
                    <router-link to="/products" class="btn btn-w-shadow btn-sm btn-back">
                        <inline-svg :src="require('@/assets/img/Arrow-prev.svg')" />
                    </router-link>
                    <div class="heading-wrapper flex items-center">
                        <button class="btn btn-transp btn-rename" @click="toggleEditName">
                            <img src="@/assets/img/Pen-Edit.svg" />
                        </button>
                        <input type="text" class="input head-controls__input" v-model="product.title" v-if="editName" />
                        <h1 class="heading-page-h1" v-else>{{ product.title }}</h1>
                    </div>
                </div>
                <div class="content__header-right">
                    <button type="button" class="btn btn-w-shadow btn-standart-2 red-color" data-target="delete-product" @click="confirmDeleteOpen(product.id,product.title)">
                        <inline-svg :src="require('@/assets/img/trash-delete.svg')" />
                        <span>{{ $translate('delete-product') }}</span>
                    </button>
                </div>
            </div>
            <div class="tabs-wrapper">
                <ul class="tabs">
                    <li class="tabs__item" v-for="item in tabsArray" :key="item.value">
                        <a class="nav-tab" @click="switchTab(item.value)" :class="isActiveTab(item.value)">
                            {{ item.title }}
                        </a>
                    </li>
                </ul>
            </div>
            <div class="content__body">
                <div class="tab-content">
                    <div id="info" class="tab-content__item" :class="isActiveTab('info')">
                        <div class="wizard-item footer-fixed">
                            <div class="items-group cards">
                                <div class="cards__col-6 mt-15">
                                    <div class="el-form">
                                        <label class="el-form__title">{{ $translate('category') }}</label>
                                        <div class="flex items-center">
                                            <DropdownAdvanced :options="categories.map((c) => ({ id: c.id, name: c.name }))" v-model="product.categoryProductsId" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="items-group cards">
                                <div class="cards__col-6">
                                    <div class="el-form mt-15 flex-auto">
                                        <label class="el-form__title">{{ $translate('product-name') }}*</label>
                                        <input type="text" class="input" v-model="product.title" />
                                    </div>
                                </div>
                                <div class="cards__col-6 mt-15">
                                    <div class="select-group">
                                        <div class="el-form flex-auto">
                                            <label class="el-form__title">{{ $translate('quantity') }}*</label>
                                            <input type="number" class="input" v-model="product.quantity" />
                                        </div>
                                        <div class="el-form flex-auto">
                                            <label class="el-form__title">{{ $translate('units') }}*</label>
                                            <input type="text" class="input" v-model="product.units" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="el-form mt-15">
                                <label class="el-form__title">{{ $translate('description') }}*</label>
                                <textarea class="textarea" placeholder="Write product description here..." v-model="product.description"></textarea>
                            </div>
                            <!--   Media   -->
                            <div class="el-form item-body border-top mt-15">
                                <label class="el-form__title mt-15" style="display: block;">{{ $translate('media') }}</label>
                                <div class="gallery" v-if="images.length">
                                    <div class="gallery__item" v-for="(m, index) in images" :key="m.id">
                                        <img :src="m.url ? m.url : m">
                                        <button class="delete-gallery__item btn" @click.prevent="deleteMediaFromAdded(index)">
                                            <inline-svg :src="require(`@/assets/img/x.svg`)"/>
                                        </button>
                                    </div>
                                </div>
                                <div 
                                    class="fake-dropzone" 
                                    :class="{ 'drop-ready': dragState }" 
                                    @drop.prevent.stop="dropMedia"  
                                    @dragenter.prevent @dragover.prevent
                                >
                                    <label>
                                        <img src="@/assets/img/upload-image-icon.svg">
                                        <span>{{ $translate('drag-&-drop-or-browse-to-choose-a-file') }}</span>
                                        <input type="file" accept="image/*" multiple @change="setProductMediaFiles">
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div id="detailed-info" class="tab-content__item" :class="isActiveTab('detailed-info')">
                        <div class="wizard-item footer-fixed">
                            <div class="items-group cards">
                                <div class="cards__col-6">
                                    <div class="el-form">
                                        <label class="el-form__title">{{ $translate('serial-number') }}</label>
                                        <input type="text" class="input" v-model="product.serial_number" />
                                    </div>
                                </div>
                                <div class="cards__col-6">
                                    <div class="el-form">
                                        <label class="el-form__title">{{ $translate('manufacturing-date') }}</label>
                                        <label class="datepicker-el">
                                            <DatePicker v-model="product.manufacturing_date" />
                                        </label>
                                    </div>
                                </div>
                                <div class="cards__col-6 mt-15">
                                    <div class="el-form">
                                        <label class="el-form__title">{{ $translate('expiry-date') }}</label>
                                        <label class="datepicker-el">
                                            <DatePicker v-model="product.expiry_date" />
                                        </label>
                                    </div>
                                </div>
                            </div>
                            <div class="items-group cards border-top mt-15">
                                <div class="cards__col-6 mt-15">
                                    <div class="el-form">
                                        <label class="el-form__title">{{ $translate('invoice-number') }}</label>
                                        <input type="text" class="input" placeholder="12345" v-model="product.invoice_number" />
                                    </div>
                                </div>
                                <div class="cards__col-6 mt-15">
                                    <div class="el-form">
                                        <label class="el-form__title">{{ $translate('purchase-order') }}</label>
                                        <input type="text" class="input" placeholder="PO-12345" v-model="product.purchase_order" />
                                    </div>
                                </div>
                            </div>
                            <div class="items-group cards border-top mt-15">
                                <!-- <div class="cards__col-6 mt-15">
                                    <div class="el-form">
                                        <label class="el-form__title">{{ $translate('rfid-number') }}</label>
                                        <input type="text" class="input" placeholder="E0040100019D0CA1" v-model="product.rfid_number" />
                                    </div>
                                </div> -->
                                <div class="cards__col-6 mt-15">
                                    <div class="el-form">
                                        <label class="el-form__title">{{ $translate('batch-id') }}</label>
                                        <input type="text" class="input" placeholder="ARW004" v-model="product.batch_id" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div id="location" class="tab-content__item" :class="isActiveTab('location')">
                        <div class="tab-content__block footer-fixed">
                            <div class="map search-map" style="border-bottom-left-radius: var(--border-radius-7); border-bottom-right-radius: var(--border-radius-7); overflow: hidden;">
                                <div class="input-with-suggestions-wrpr">
                                    <a href="#" style="position: absolute;left: 7px;top: 5px;width: 30px;height: 30px;" @click.prevent="updateProductDataMethod"></a>
                                    <input type="text" style="z-index:2"
                                        placeholder="USA, New York, New York" 
                                        class="input bg-left search-icon"
                                        name="location"
                                        v-model="product.location.address"
                                        @change="updateProductDataMethod"
                                    >
                                    <ul v-if="showSuggestions">
                                        <li v-for="(locationItem, locationItemIndex) in suggestionsLocations" :key="locationItem.label+'-'+locationItemIndex">
                                            <a href="#" @click.prevent="setLocation({ lat: locationItem.latitude, lng: locationItem.longitude }, true)">
                                                {{ locationItem.label }}
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                                <div id="product-map" style="width: 100%; height: calc(100vh - 300px);"></div>
                            </div>
                        </div>
                    </div>
                    <div id="device" class="tab-content__item" :class="isActiveTab('device')">
                        <div class="addition footer-fixed">
                            <div class="headline-wrapper flex items-center">
                                <div class="headline headline--sm flex-auto">
                                    <h2 class="headline__title">{{ $translate('assign-devices') }}</h2>
                                    <p class="headline__text">{{ $translate('here-you-can-find-devices-assigned-to-your-prouct') }}</p>
                                </div>
								<WhitePlusButton @click="addDeviceOpen">{{ $translate('add-devices') }}</WhitePlusButton>
                            </div>
                            <ul class="editable-list">
                                <li class="editable-list__item" v-for="d in fieldValues('devices')" :key='d.id'>
                                    <div class="data-row">
                                        <ul class="data-row__list">
                                            <li class="data-row__item">{{d.name}}</li>
                                            <li class="data-row__item">
                                                <span class="data-row__icon">
													<DeviceIcon :category="d.category"></DeviceIcon>
                                                </span>
                                                {{d.category}}
                                            </li>
                                            <li class="data-row__item">{{d.location||'none'}}</li>
                                        </ul>
                                        <div class="data-row__controls">
                                            <button type="button" class="btn btn-icon red-color" @click="deleteFieldItem('devices',d.id)">
                                                <inline-svg :src="require(`@/assets/img/Delete-circle.svg`)"/>
                                            </button>
                                        </div>
                                    </div>
                                </li>
                            </ul>
                            <WhitePlusButton @click="addDeviceOpen">{{ $translate('add-device') }}</WhitePlusButton>
                        </div>
                    </div>
                    <div id="parent" class="tab-content__item" :class="isActiveTab('parent')">
                        <div class="addition footer-fixed">
                            <div class="headline-wrapper flex items-center">
                                <div class="headline headline--sm flex-auto">
                                    <h2 class="headline__title">{{ $translate('parent-products') }}</h2>
                                    <p class="headline__text">{{ $translate('here-you-can-add-a-parent-product-to-this-product') }}</p>
                                </div>
                                <WhitePlusButton @click="addParentProductOpen">{{ $translate('add-product') }}</WhitePlusButton>
                            </div>
                            <ul class="editable-list">
                                <li class="editable-list__item" v-for="p in product.parent_products" :key="p.id">
                                    <div class="data-row">
                                        <ul class="data-row__list">
                                            <li class="data-row__item">Name: {{getProductName(p.id)}}</li>
                                            <li class="data-row__item">Type of event: {{eventTypes[p.type] || p.type}}</li>
                                            <li v-if="getProductFullObject(p.id).quantity">
                                                Quantity: <span style="text-transform:uppercase;">{{ getProductFullObject(p.id).quantity || '0' }} {{ getProductFullObject(p.id).units }}</span>
                                            </li>
                                        </ul>
                                        <div class="data-row__controls">
                                            <button type="button" class="btn btn-icon" @click="editParentProduct(p)">
                                                <inline-svg :src="require(`@/assets/img/ico-gear.svg`)"/>
                                            </button>
                                            <button type="button" class="btn btn-icon red-color" @click="deleteParentProducts(p.id)">
                                                <inline-svg :src="require(`@/assets/img/Delete-circle.svg`)"/>
                                            </button>
                                        </div>
                                    </div>
                                </li>
                            
                            </ul>
                            <WhitePlusButton @click="addParentProductOpen">{{ $translate('add-product') }}</WhitePlusButton>
                        </div>
                    </div>
                    <div id="farm" class="tab-content__item" :class="isActiveTab('farm')">
                        <div class="addition footer-fixed">
                            <div class="headline-wrapper flex items-center">
                                <div class="headline headline--sm flex-auto">
                                    <h2 class="headline__title">{{ $translate('link-to-a-farm') }}</h2>
                                    <p class="headline__text">{{ $translate('here-you-can-link-farm-items-to-a-product') }}</p>
                                </div>
                                <div class="buttons-group add-buttons">
                                    <WhitePlusButton @click="paddocksPopupOpen('herd')">{{ $translate('add-herd') }}</WhitePlusButton>
                                    <WhitePlusButton @click="paddocksPopupOpen('building')">{{ $translate('add-building') }}</WhitePlusButton>
                                    <WhitePlusButton @click="paddocksPopupOpen('field')">{{ $translate('add-field') }}</WhitePlusButton>
                                    <WhitePlusButton @click="paddocksPopupOpen('paddocks')">{{ $translate('add-paddock') }}</WhitePlusButton>
                                </div>
                            </div>
                            <ul class="editable-list">
                                <template v-if="selectedPaddocks.length">
                                    <li class="editable-list__item" v-for="paddockItem in selectedPaddocks" :key="paddockItem.id">
                                        <div class="data-row">
                                            <ul class="data-row__list">
                                                <li class="data-row__item">Field name: {{ paddockItem.basic_info.name }}</li>
                                                <li class="data-row__item" style="text-transform: capitalize;">
                                                    Total area: {{ 
                                                        paddockItem.type !== 'herd' && paddockItem.basic_info.total_area ? paddockItem.basic_info.total_area : 
                                                        paddockItem.type === 'herd' ? paddockItem.basic_info.type : paddockItem.type
                                                    }}
                                                </li>
                                                <li class="data-row__item" style="text-transform: capitalize;" v-if="paddockItem.basic_info.plots_config && paddockItem.basic_info.plots_config?.crop">
                                                    Crop: {{ paddockItem.basic_info.plots_config?.crop }}
                                                </li>
                                                <li class="data-row__item" style="text-transform: capitalize;" v-if="paddockItem.basic_info.plots_config && paddockItem.basic_info.plots_config.variety">
                                                    Variety: {{ paddockItem.basic_info.plots_config.variety }}
                                                </li>
                                            </ul>
                                            <div class="data-row__controls">
                                                <router-link :to="{ path: '/farm-map', query: { 'edit': paddockItem.id } }" class="btn btn-icon">
                                                    <inline-svg :src="require(`@/assets/img/ico-gear.svg`)"/>
                                                </router-link>
                                                <button type="button" class="btn btn-icon red-color" @click="paddockItemRemove(paddockItem.id)">
                                                    <inline-svg :src="require(`@/assets/img/Delete-circle.svg`)"/>
                                                </button>
                                            </div>
                                        </div>
                                    </li>
                                </template>
                                <li v-if="!selectedPaddocks.length">
                                    <p>{{ $translate('please-link-farm-items-to-a-product') }}</p>
                                </li>
                            </ul>
                            <div class="buttons-group add-buttons">
                                <WhitePlusButton @click="paddocksPopupOpen('herd')">{{ $translate('add-herd') }}</WhitePlusButton>
                                <WhitePlusButton @click="paddocksPopupOpen('building')">{{ $translate('add-building') }}</WhitePlusButton>
                                <WhitePlusButton @click="paddocksPopupOpen('field')">{{ $translate('add-field') }}</WhitePlusButton>
                                <WhitePlusButton @click="paddocksPopupOpen('paddocks')">{{ $translate('add-paddock') }}</WhitePlusButton>
                            </div>
                        </div>
                    </div>
                    <div id="code-settings" class="tab-content__item" :class="isActiveTab('code-settings')">
                        <div class="wizard-item footer-fixed" v-if="activeTab === 'code-settings'">
                            <div class="headline headline--sm mt-15">
                                <div class="flex items-center" style="margin-bottom: 10px;">
                                    <h2 class="headline__title" style="margin: 0;">{{ $translate('company-data') }}</h2>
                                    <label class="module__check ml-10">
                                        <input type="checkbox" v-model="companyDataAllSelected" @change="allCheckboxDataChange('companyData', 'companyDataAllSelected')">
                                        <span class="check"></span>
                                    </label>
                                </div>
                                <p class="headline__text">{{ $translate('select-info-that-you-want-to-show-in-qr-code') }}</p>
                            </div>
                            <div class="cards flex"> 
                                <div class="cards__col-4 cards__col-md-6 mt-15 flex flex-col" v-for="colNumber in [0,1,2]" :key="colNumber" >
                                    <label class="module__check" v-for="(inputKey, index) in getCell(QRSettings.companyData,colNumber)" :key="inputKey" :class="index>0?'mt-15':null">
                                        <input type="checkbox" name="privacy_policy" v-model="QRSettings.companyData[inputKey]" @change="checkCheckedInputs">
                                        <span class="check"></span>
                                        <span class="text">
                                            <span class="input-text">
                                                {{ inputKey.replaceAll("_"," ") }}
                                            </span>
                                        </span>
                                    </label>
                                </div>
                            </div>
                            <div class="cards items-group border-top mt-15">
                                <div class="cards__col">
                                    <div class="headline-wrapper flex items-center">
                                        <div class="headline headline--sm flex-auto mt-15">
                                            <h2 class="headline__title">{{ $translate('assign-devices') }}</h2>
                                            <p class="headline__text">{{ $translate('here-you-can-find-devices-assigned-to-your-product') }}</p>
                                        </div>
                                        <WhitePlusButton @click="addDeviceOpen">{{ $translate('add-devices') }}</WhitePlusButton>
                                    </div>
                                    <ul class="editable-list" v-if="product && devices && devices.length">
                                        <!-- <li class="editable-list__item" v-for="d in fieldValues('QRDevices',devices)" :key="d.id"> -->
                                        <li class="editable-list__item" v-for="d in devices.filter(itemF => arrayFields.devices.includes(itemF.id))" :key="d.id">
                                            <div class="data-row flex-col has-spoiler">
                                                <div class="data-row__head flex w-full">
                                                    <ul class="data-row__list">
                                                        <li class="data-row__item">{{d.name}}</li>
                                                        <!-- <li class="data-row__item">
                                                            <span class="data-row__icon"><DeviceIcon :category="d.category" /></span> 
                                                            {{d.category}}
                                                        </li> -->
                                                        <li class="data-row__item" v-if="QRSettings.deviceData.find(itemM => itemM.deviceID === d.id) && QRSettings.deviceData.find(itemM => itemM.deviceID === d.id).parameterObeservedOptions">
                                                            <span class="data-row__icon" v-if="QRSettings.deviceData.find(itemM => itemM.deviceID === d.id).parameterObserved">
                                                                <DeviceIcon :category="QRSettings.deviceData.find(itemM => itemM.deviceID === d.id).parameterObserved" />
                                                            </span> 
                                                            <!-- {{ d.category }} -->
                                                            <DropdownAdvanced
                                                                :options="QRSettings.deviceData.find(itemM => itemM.deviceID === d.id).parameterObeservedOptions"
                                                                v-model="QRSettings.deviceData.find(itemM => itemM.deviceID === d.id).parameterObserved"
                                                            />
                                                        </li>
                                                        <li class="data-row__item">{{ d.location && d.location.address ? d.location.address : ''}}</li>
                                                    </ul>
                                                    <div class="data-row__controls" >
                                                        <button type="button" class="btn btn-icon red-color" @click="deleteFieldItem('devices',d.id)">
                                                            <inline-svg :src="require(`@/assets/img/Delete-circle.svg`)"/>
                                                        </button>
                                                    </div>
                                                </div>
                                                <div 
                                                    class="data-row__spoiler spoiler" 
                                                    v-if="QRSettings.deviceData.length && QRSettings.deviceData.map(itemM => itemM.deviceID).includes(d.id)"
                                                >
                                                    <div class="spoiler__button flex items-center" @click="opedOptions">
                                                        <span class="flex-auto pointer-events-none">{{ $translate('device-info') }}</span>
                                                        <inline-svg class="pointer-events-none" :src="require(`@/assets/img/Arrow-down.svg`)"/>
                                                    </div>
                                                    <div class="spoiler__body flex flex-col">
                                                        <label class="module__check">
                                                            <input 
                                                                type="checkbox" name="privacy_policy" 
                                                                v-model="QRSettings.deviceData.find(itemM => itemM.deviceID === d.id).atDayCreation"
                                                            >
                                                            <span class="check"></span>
                                                            <span class="text">
                                                                {{ devCats.find(itemF => itemF.id === QRSettings.deviceData.find(itemM => itemM.deviceID === d.id).parameterObserved).name }} at the day of product creation
                                                            </span>
                                                        </label>
                                                        <label class="module__check mt-15">
                                                            <input type="checkbox" name="privacy_policy" v-model="QRSettings.deviceData.find(itemM => itemM.deviceID === d.id).duringDayCreation">
                                                            <span class="check"></span>
                                                            <span class="text">
                                                                {{ devCats.find(itemF => itemF.id === QRSettings.deviceData.find(itemM => itemM.deviceID === d.id).parameterObserved).name }} during the day/week/month of the product creation
                                                            </span>
                                                        </label>
                                                        <label class="module__check mt-15">
                                                            <input 
                                                                type="checkbox" name="privacy_policy" 
                                                                v-model="QRSettings.deviceData.find(itemM => itemM.deviceID === d.id).duringSmartCondition"
                                                            >
                                                            <span class="check"></span>
                                                            <span class="text">
                                                                {{ devCats.find(itemF => itemF.id === QRSettings.deviceData.find(itemM => itemM.deviceID === d.id).parameterObserved).name }} during the smart condition in the dispatch
                                                            </span>
                                                        </label>
                                                        <label class="module__check mt-15">
                                                            <input 
                                                                type="checkbox" name="privacy_policy" 
                                                                v-model="QRSettings.deviceData.find(itemM => itemM.deviceID === d.id).maxMinValue.checked"
                                                            >
                                                            <span class="check"></span>
                                                            <span class="text">
                                                                {{ $translate('max/min-value-of-the') }} <span style="text-transform: lowercase;margin: 0 5px;">
                                                                    {{ devCats.find(itemF => itemF.id === QRSettings.deviceData.find(itemM => itemM.deviceID === d.id).parameterObserved).name }}
                                                                </span> {{ $translate('during-a-time-range') }}
                                                            </span>
                                                        </label>
                                                        <div class="cards flex" style="max-width: 630px;">
                                                            <div class="cards__col-6 mt-15">
                                                                <div class="buttons-group">
                                                                    <DatePicker v-model="QRSettings.deviceData.find(itemM => itemM.deviceID === d.id).maxMinValue.minDate" />
                                                                    <TimePicker v-model="QRSettings.deviceData.find(itemM => itemM.deviceID === d.id).maxMinValue.minTime" />
                                                                </div>
                                                            </div>
                                                            <div class="cards__col-6 mt-15">
                                                                <div class="buttons-group">
                                                                    <DatePicker v-model="QRSettings.deviceData.find(itemM => itemM.deviceID === d.id).maxMinValue.maxDate" />
                                                                    <TimePicker v-model="QRSettings.deviceData.find(itemM => itemM.deviceID === d.id).maxMinValue.maxTime" />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <label class="module__check mt-15">
                                                            <input 
                                                                type="checkbox" name="privacy_policy" exactValue 
                                                                v-model="QRSettings.deviceData.find(itemM => itemM.deviceID === d.id).exactValue.checked"
                                                            >
                                                            <span class="check"></span>
                                                            <span class="text">
                                                                {{ $translate('exact-value-of-the') }} <span style="text-transform: lowercase;margin: 0 5px;">
                                                                    {{ devCats.find(itemF => itemF.id === QRSettings.deviceData.find(itemM => itemM.deviceID === d.id).parameterObserved).name }}
                                                                </span> {{ $translate('during-a-time-range') }}
                                                            </span>
                                                        </label>
                                                        <div class="cards flex" style="max-width: 630px;">
                                                            <div class="cards__col-6 mt-15">
                                                                <div class="buttons-group">
                                                                    <DatePicker v-model="QRSettings.deviceData.find(itemM => itemM.deviceID === d.id).exactValue.firstDate" />
                                                                    <TimePicker v-model="QRSettings.deviceData.find(itemM => itemM.deviceID === d.id).exactValue.firstTime" />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </li>
                                    </ul>
                                    <WhitePlusButton @click="addDeviceOpen">{{ $translate('add-devices') }}</WhitePlusButton>
                                </div>
                            </div>
                            <div class="cards items-group border-top mt-15">
                                <div class="cards__col">
                                    <div class="headline headline--sm mt-15">
                                        <div class="flex items-center" style="margin-bottom: 10px;">
                                            <h2 class="headline__title" style="margin: 0;">{{ $translate('product-chain-data') }}</h2>
                                            <label class="module__check ml-10">
                                                <input type="checkbox" v-model="productChainDataAllSelected" @change="allCheckboxDataChange('productData', 'productChainDataAllSelected')">
                                                <span class="check"></span>
                                            </label>
                                        </div>
                                        <p class="headline__text">{{ $translate('select-info-that-you-want-to-show-in-qr-code') }}</p>
                                    </div>								
                                    <div class="cards flex">
                                        <template v-for="inputKey in Object.entries(QRSettings.productData)">
                                            <div 
                                                class="cards__col-4 cards__col-md-6" :key="inputKey[0]"
                                                v-if="inputKey[0] !== 'transaction_history' && inputKey[0] !== 'device_rule_log'"
                                            >
                                                <label class="module__check mt-15">
                                                    <input type="checkbox" name="privacy_policy" v-model="QRSettings.productData[inputKey[0]]" @change="checkCheckedInputs">
                                                    <span class="check"></span>
                                                    <span class="text">
                                                        <span class="input-text">
                                                            {{ inputKey[0].replaceAll("_"," ") }}
                                                        </span>
                                                    </span>
                                                </label>
                                            </div>
                                        </template>
                                    </div>
                                </div>
                            </div>
                            <div class="cards items-group border-top mt-15">
                                <div class="cards__col">
                                    <div class="cards flex">
                                        <div class="cards__col-4 cards__col-md-6">
                                            <label class="module__check mt-15">
                                                <input type="checkbox" v-model="actions_info">
                                                <span class="check"></span>
                                                <span class="text">
                                                    <span class="input-text">{{ $translate('actions-info') }}</span>
                                                </span>
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <QRCustom
                        v-if="activeTab === 'qr-custom'"
                        :product="product"
                    />
                </div>
            </div>
        </main>

		<ConfirmAction 
            v-if="deletePopupInfo.show" 
            :title="$translate('delete-product?')" 
            :message="$translate('do-you-confirm-delete-a-product') + ' ' + deletePopupInfo.name+ '?'  " 
            @close="confirmDeleteClose" 
            @confirm="confirmDeleteAction" 
            :processing="dProcessing"
        />
        <!-- 
            before translate
            title="Delete Product?" 
            :message="'Do you confirm delete a product '+ deletePopupInfo.name+ '?'  " 
         -->

        <FooterTabsControl
            :previous="true"
            cancelRoute="/products"
            :offNextButton="true"
            :confirmName="$translate('save')"
            @confirm="updateProduct"
            @swipeTab="swipeTab"
            :pageTabs="pageTabs"
            :activeTab="activeTab"
            :processing="processing"
        />
        <!-- before translateconfirmName="Save" -->

        <AddDevice
            v-if="addDevicesPopupInfo.show"
            :devices="addDevicesPopupInfo.devices"
            @close="addDeviceClose"
            @submit="addDeviceSubmit"
            :title="$translate('assign-devices-to-product')"
        />
        <!-- before translating title="Assign Device(s) to product" -->

        <AddParentProduct
            v-if="addParentProductsPopupInfo.show"
            :products="addParentProductsPopupInfo.products"
            :product="addParentProductsPopupInfo.product"
            @close="addParentProductsClose"
            @submit="addParentProductsSubmit"
        />

        <AddDevice
            v-if="addQRDevicesPopupInfo.show"
            :devices="addQRDevicesPopupInfo.devices"
            @close="addQRDeviceClose"
            @submit="addQRDeviceSubmit"
            :title="$translate('assign-devices-to-product')"
        />
        <!-- before translating title="Assign Device(s) to product" -->

        <ChangePaddockBuildingPopup
            v-if="paddocksPopup.show"
            :multiple="true"
            :paddocks="paddocksPopup.paddocks"
            @close="paddocksPopupClose"
            @submit="submitPaddocksPopup"
            :title="$translate('link-to-a-farm')"
            :value="product.paddocks"
        />
        <!-- before translate title="Link to a Farm" -->
    </div>
</template>

<script>
import { API, Storage } from "aws-amplify";
import { 
    createProduct, 
    updateProduct, 
    updateDevice,
    createMedia,
	deleteMedia, 
} from "@/graphql/mutations";

import { getProduct } from "@/graphql/queries";

import Base from "@/components/base";
import Popups from "@/components/popups";
import Buttons from "@/components/buttons";
import ChangePaddockBuildingPopup from '@/components/popups/ChangePaddockBuildingPopup';

import QRCustom from '@/views/products/QRCustom';

import { compareData } from "@/helpers";
import { mixTabs,mixDeleteItem,mixProcessing,mixArrayFields } from "@/mixins";
import { eventTypes } from "@/config/products";
import { devCats } from '@/config';

export default {
    props: ["productID"],
    name: "SingleProductEdit",
    components: {
        ...Base,
		...Popups,
		...Buttons,
        ChangePaddockBuildingPopup,
        QRCustom
    },
	mixins: [mixTabs, mixDeleteItem, mixProcessing, mixArrayFields],
    data() {
        return {
            product: {
                title: "Untitled Product",
                status: "active",
                companyProductsId: null,
                paddocks: [],
                location:{
                    address:null
                }
            },
            QRSettings:{
				companyData:{
					location:false,
					history_of_company:false,
					media:false,
					documentation:false,
					year_of_foundation:false,
					production_per_year:false,
					production_area:false,
					soils:false,
					cellar_work:false,
					productivity:false,
					certifications:false,
					philosophy:false,
				},
				deviceData:[],
				productData:{
					datetime_creation: false ,
					rules_business_conditions: false,
					datetime_dispatch: false,
					datetime_receive: false,
					datetime_return: false,
					transaction_history: true,
					device_rule_log: true,
					basic_info: false,
					parent_products: false,
					list_of_dispatches: false,
					linked_IOT_devices: false,
                    smart_conditions: false,
				}
			},
            actions_info: false,
            companyDataAllSelected: true,
            productChainDataAllSelected: true,
            editName:false,
            arrayFields: {
                config: {
                    "devices": {
                        originalName:"",
                        storeName:""
                    }
                },
                devices:[]
            },            

            backup: {},

			//config delete item
            deleteQueryType:"deleteProduct",
			deleteQueryPostRoute:"/products",
			//config tabs
			pageTabs:['info', 'detailed-info', 'location', 'device', 'parent', 'farm', 'code-settings'],
            addParentProductsPopupInfo:{
				show:false,
				products:null,
				product:null
			},
            addQRDevicesPopupInfo:{
                devices:null,
                show:false
            },
            paddocksPopup: {
                show: false,
                paddocks: []
            },

            media: [],
			deleteMediaTarget: null,
			deleteMediaType: 'media',
			mediaPopup: false,
			dragState: false,
			productMediaFiles: [],
			images: [],
            imagesChange: false,
            tabsArray: [
				{ title: 'Basic Info', value: 'info' },
				{ title: 'Detailed info', value: 'detailed-info' },
				{ title: 'Location', value: 'location' },
				{ title: 'Assign Device', value: 'device' },
				{ title: 'Parent Products', value: 'parent' },
				{ title: 'Farm', value: 'farm' },
				{ title: 'QR Code Settings', value: 'code-settings' },
                //{ title: 'QR Custom Settings', value: 'qr-custom' },
			],
            devCats: devCats,
            suggestionsLocations: [],
			showSuggestions: false,
        };
    },

    computed: {
        edit() {
            return this.productID !== "new";
        },
        companyData() {
            return this.$store.state.companyData || {};
        },
        products() {
            return this.$store.state.products || [];
        },
        categories() {
            return this.$store.state.categories || [];
        },
        devices(){
			return this.$store.state.devices || []
		},
        paddocks(){
            return this.$store.state.paddocks;
        },
		selectedPaddocks(){
			return this.paddocks.filter(item => this.product.paddocks.includes(item.id));
		},
        eventTypes(){
			return eventTypes
		},
        allProducts(){
			return [ ...this.$store.state.products, ...this.$store.state.receivedProducts ]
		}
    },

    watch: {
    	arrayFields: {
			deep: true,
			handler(){
				this.arrayFields.devices.forEach(async element => {
                    if(!this.QRSettings.deviceData.length || !this.QRSettings.deviceData.map(item => item.deviceID).includes(element)){
                        let findDevice = this.devices.find(item => item.id === element);
                        let deviceConfigId = findDevice && findDevice.config && findDevice.config.device_id ? findDevice.config.device_id : null;
                        let parameterObeservedOptions = await this.generateParameterObeserved(deviceConfigId, findDevice);
                        if(parameterObeservedOptions.length){
                            this.QRSettings.deviceData.push({
                                deviceID: element,
                                parameterObserved: parameterObeservedOptions[0].id,
                                parameterObeservedOptions: parameterObeservedOptions,
                                atDayCreation: false,
                                duringDayCreation: false,
                                duringSmartCondition: false,
                                maxMinValue: {
                                    checked: false,
                                    maxDate: null,
                                    maxTime: null,
                                    minDate: null,
                                    minTime: null
                                },
                                exactValue: {
                                    checked: false,
                                    firstDate: null,
                                    firstTime: null,
                                    secondDate: null,
                                    secondTime: null
                                }
                            });
                        }
                    }

                    if(this.arrayFields.devices.length < this.QRSettings.deviceData.length){
                        this.QRSettings.deviceData.forEach(element2 => {
                            if(!this.arrayFields.devices.includes(element2.deviceID)){
                                this.QRSettings.deviceData.splice(this.QRSettings.deviceData.findIndex(item => item.deviceID === element2.deviceID), 1);
                            }
                        });
                    }
				});
			}
		}
	},

    methods: {
        async generateParameterObeserved(deviceId, device){
            if(deviceId){
                let parameterObeservedOptions = [];
                let deviceDataFromApi = [];

                if(device.category === 'carbon-dioxide' || device.category === 'phosphine') {
                    deviceDataFromApi = await this.$provider.centaurAPI.getRealDeviceData(deviceId);

                    for (let index = 0; index < deviceDataFromApi.length; index++) {
                        let devCatsFinded = devCats.find(dItem => dItem.centaur_type_id === deviceDataFromApi[index].type);
                        
                        if(devCatsFinded && !parameterObeservedOptions.map(item => item.id).includes(devCatsFinded.id)){
                            parameterObeservedOptions.push({
                                name: devCatsFinded.name,
                                id: devCatsFinded.id
                            });
                        }
                    }
                } else {
                    // let dataFromxFarm = await this.$provider.xFarm.getDeviceData(deviceId);
                    let parameters = [];

                    if(device.category === 'temperature' || device.category === 'temperature-humidity' || device.category === 'weather-station-xsense'){
                        parameters = [
                            ['Temperature', 'temperature']
                        ];
					}

					if(device.category === 'leaf-moisture'){
                        parameters = [
                            ['Leaf moisture', 'leaf-moisture']
                        ];
					}

					if(device.category === 'soil-moisture'){
                        parameters = [
                            ['Soil moisture', 'soil-moisture']
                        ];
					}

                    if(device.category === 'temperature-humidity'){
                        parameters = [
                            ['Dew point temp', 'dew-point-temp'],
                            ['Air humidity', 'air-humidity']
                        ];
                    }

                    if(device.category === 'weather-station-xsense'){
                        parameters = [
                            ['Dew point temp', 'dew-point-temp'],
                            ['Air humidity', 'air-humidity'],
                            ['Rain Meter', 'rain-meter'],
                            ['Average wind speed', 'average-wind-speed'],
                            ['Max wind speed', 'max-wind-speed'],
                            ['Solar Radiation', 'solar-radiation']
                        ];
                    }

                    for (let index = 0; index < parameters.length; index++) {
                        parameterObeservedOptions.push({
                            name: parameters[index][0],
                            id: parameters[index][1]
                        });
                    }
                }

				return parameterObeservedOptions;
            } else {
				return null;
			}
        },
        allCheckboxDataChange(qrDataName, checkboxVar){
            let canceledItems = ['transaction_history', 'device_rule_log'];

            Object.entries(this.QRSettings[qrDataName]).map(item => item[0]).forEach(element => {
                if(!canceledItems.includes(element)) this.QRSettings[qrDataName][element] = this[checkboxVar];
            })
        },
        checkCheckedInputs(){
            [['companyData','companyDataAllSelected'],['productData','productChainDataAllSelected']].forEach(itemD => {
                let settedData = true;
                Object.entries(this.QRSettings[itemD[0]]).map(item => item[1]).forEach(element => {
                    if(!element) settedData = false;
                });
                this[itemD[1]] = settedData;
            })
        },
		setProductMediaFiles(e){
			this.productMediaFiles = e.target.files;
			
			this.images = [];
			let fileList = Array.prototype.slice.call(e.target.files);
			fileList.forEach(f => {

				if(!f.type.match("image.*")) {
				    return;
				}

				let reader = new FileReader();
				let that = this;

				reader.onload = function (e) {
					that.images.push(e.target.result);
				}
				reader.readAsDataURL(f); 
			});

            this.imagesChange = true;
		},
        deleteMediaFromAdded(index){
			var newFileList = Array.from(this.productMediaFiles);
			newFileList = newFileList.splice(index, 1);
			this.images.splice(index, 1);
			this.productMediaFiles = newFileList;
            this.imagesChange = true;
		},
		async uploadMedia(productId) {
            if(this.imagesChange){
                if(this.product.media && this.product.media.items && this.product.media.items.length){
                    this.product.media.items.forEach(async item => {
                        await API.graphql({
                            query: deleteMedia,
                            variables: {
                                input: { id: item.id }
                            }
                        })
                    })
                }

                const files = this.productMediaFiles;

                if(!files.length) return

                Object.values(files).forEach(async file => {
                    let { name } = file;
                    let s3File = await Storage.put(`media/${name}`, file);
                    let input = {
                        productMediaId: productId,
                        key: s3File.key
                    }
                    let newMedia = await API.graphql({
                        query: createMedia,
                        variables: { 
                            input 
                        }
                    })
                    let url = await Storage.get(s3File.key);
                    this.media.push({
                        key: s3File.key,
                        id: newMedia.data.createMedia.id,
                        url
                    })
                })
            }
		},
		dropMedia(ev) {
			const event = { target: ev.dataTransfer };

			this.images = [];
			let fileList = Array.prototype.slice.call(event.target.files);
			fileList.forEach(f => {

				if(!f.type.match("image.*")) {
				return;
				}

				let reader = new FileReader();
				let that = this;

				reader.onload = function (event) {
					that.images.push(event.target.result);
				}
				reader.readAsDataURL(f); 
			});

			this.productMediaFiles = event;
			this.dragState = false;

            this.imagesChange = true;
		},
        async getAttachments() {
			let requests;

			if(this.product && this.product.media && this.product.media.items) {
				requests = this.product.media.items.map(async item => { 
					return {
						id: item.id,
						key: item.key,
						url: await Storage.get(item.key) 
					}	
				});
				Promise.all(requests).then(responses => this.images = responses);
			}			
		},
        getProductName(id){
			return this.products.find(p=>p.id==id)?.title
		},
        getProductFullObject(id){
			return this.allProducts.find(p=>p.id==id)
		},
        addQRDeviceOpen(){
			// console.log("test",this.devices,this.fieldValuesFree('QRDevices',this.devices));
			// this.addQRDevicesPopupInfo.devices=this.fieldValuesFree('QRDevices',this.devices)
			this.addQRDevicesPopupInfo.devices = [...this.devices];
            this.addQRDevicesPopupInfo.show = true;
        },
        addQRDeviceClose(){
			this.addQRDevicesPopupInfo.show=false
			this.addQRDevicesPopupInfo.devices=null
        },
        addQRDeviceSubmit(devices){
			this.addFieldItems('QRDevices',devices)
			this.addQRDeviceClose()
        },
        addParentProductOpen(){
			this.addParentProductsPopupInfo.show=true
			this.addParentProductsPopupInfo.products=this.allProducts
        },
		editParentProduct(product){
			this.addParentProductsPopupInfo.show=true
			this.addParentProductsPopupInfo.products=this.allProducts
			this.addParentProductsPopupInfo.product=product
        },
        addParentProductsClose(){
			this.addParentProductsPopupInfo.show=false
			this.addParentProductsPopupInfo.products=null
			this.addParentProductsPopupInfo.product=null
        },
        addParentProductsSubmit(product){
			let index = this.product.parent_products.findIndex(p=>p.id==product.id)
			if(index==-1){
				this.product.parent_products.push(product)
			}else{		
				this.product.parent_products[index]=product
			}
			this.addParentProductsClose()
        },
        deleteParentProducts(id){
			this.product.parent_products.splice(this.product.parent_products.findIndex(p=>p.id==id),1)
        },
        async initMap() {
            // console.log("initMap",this.current);
			let payload={
				markerType:"product",
                lat:this.product.location.lat,
                lng:this.product.location.lng,
                
			}
			this.$provider.gmap.createMap('product-map', payload, (res) => {
				this.setLocation(res)
			});
		},
        async setLocation(cords, setMapLocation = false) {
			let query = Object.values(cords).join();
			const { data } = await this.$provider.positionStack.getReverse(query);
			if(data.length) {
				const {
					label,
					country,
					city,
					region,
					locality,
					county
				} = data[0];
				console.log("data[0]:", data[0]);
				let location={
					...cords,
					address:locality || county || label
				}
				this.product.location=location
				console.log("l",location);
			}
			
            if(setMapLocation){
				this.$provider.gmap.setCords('product-map', cords);
				this.showSuggestions = false;
			}
		},
        async geocodeForward(address) {
			const { data } = await this.$provider.positionStack.getForward(address);

			if(data.length) {
                this.suggestionsLocations = data;
				this.showSuggestions = true;
                
                // const { 
				// 	latitude, 
				// 	longitude,
				// } = data[0];
				// this.$provider.gmap.setCords('product-map', { lat: latitude, lng: longitude })
				// this.setLocation({ lat: latitude, lng: longitude })
			}
		},
        async updateProductDataMethod(ev) {
			
			const target = ev.target;
			const value = target.value;
			const name = target.name;
			console.log('test',name,value)
			
			if(name === 'location') this.geocodeForward(value);

		},
        toggleEditName(){
            this.editName=!this.editName
        },  
        async getProductInfo() {
            const product = await API.graphql({
                query: getProduct,
                variables: {
                    id: this.productID,
                },
            }).then((res) => res.data.getProduct);
            if(product.status==='received'){
                this.$router.push('/products')
            }
            this.setProductInfo(product)
            this.backup = JSON.parse(JSON.stringify(product));
            if(this.product.qr_settings.devices){
                this.QRSettings.deviceData = this.product.qr_settings.devices.map(item => JSON.parse(item.config));
            }

            this.actions_info = product.qr_settings.actions_info;
                
            Object.keys(product.qr_settings).forEach(element => {
                Object.keys(this.QRSettings.companyData).forEach(element2 => {
                    if(element === element2) {
                        this.QRSettings.companyData[element2] = product.qr_settings[element];
                    }
                })
                Object.keys(this.QRSettings.productData).forEach(element2 => {
                    if(element === element2) {
                        this.QRSettings.productData[element2] = product.qr_settings[element];
                    }
                })
            })
        },
        
        setProductInfo(newProduct){
            newProduct={...newProduct}
            let r=this.transformFieldToId(newProduct.devices.items)
            this.setFieldItems('devices',r)
            this.product={...newProduct}
        },

        restoreFromBackup() {
            this.setProductInfo(this.backup)
        },

        paddocksPopupOpen(type){
            this.paddocksPopup = {
                show: true,
                paddocks: [...this.paddocks].filter(item => (item.type === type)),
            };
        },
        paddocksPopupClose(){
            this.paddocksPopup = {
                show: false,
                paddocks: []
            };
        },
        submitPaddocksPopup(submitedPaddocks){
            this.product.paddocks = submitedPaddocks;
            this.paddocksPopupClose();
        },
		paddockItemRemove(id){
			this.product.paddocks.splice(this.product.paddocks.findIndex(item => item === id), 1);
		},

        getCell(obj,colNumber){
			let keys = Object.keys(obj)
			let size=keys.length
			let fullColsCount=Math.floor(size/3);
			let lastColsCount=size-fullColsCount*3
			let rows=[]
			for(let i=0;i<fullColsCount;i++){
				rows.push(keys.splice(0,3))
			}
			if(lastColsCount){
				rows.push(keys.splice(0,lastColsCount))
			}
			let col=[]
			rows.forEach(r=>{
				if(r[colNumber]){
					col.push(r[colNumber])
				}
			})
			return col
		},
        opedOptions(ev) {
            ev.target.classList.toggle("active");
            ev.target.nextElementSibling.classList.toggle("active");
        },

        async categoryChanged(ev) {
            const { value } = ev.target;
            const { id, name } = value;
            console.log(value);

            this.product.categoryProductsId = id;
        },
        async updateProduct() {
            this.processing = true;

			let devicesConfigs = [];

			this.QRSettings.deviceData.forEach(item => {
				devicesConfigs.push({
					device_type: null,
					// config: JSON.stringify(this.QRSettings.deviceData.find(itemF => itemF.deviceID === item))
					config: JSON.stringify(item)
				})
			})

            // compare with
            const update = compareData(this.product, this.backup,["location","parent_products","paddocks",]);

            const product = await API.graphql({
                query: updateProduct,
                variables: {
                    input: {
                        id: this.productID,
                        ...update,
                        qr_settings: {
                            ...this.QRSettings.companyData,
                            ...this.QRSettings.productData,
                            devices: devicesConfigs,
                            actions_info: this.actions_info,
                        }
                    },
                },
            }).then((res) => res.data.updateProduct);

            await this.uploadMedia(product.id);

            let compareDevices=this.compareFieldDiff('devices',this.backup.devices.items)
            let requestsAdd = compareDevices.toAdd.map((deviceID) =>
                API.graphql({
                    query: updateDevice,
                    variables: {
                        input: {
                            id: deviceID,
               				productID: product.id
                        },
                    },
                }).then((res) => res.data.updateProduct)
            );
            let requestsDelete = compareDevices.toDelete.map((deviceID) =>
                API.graphql({
                    query: updateDevice,
                    variables: {
                        input: {
                            id: deviceID,
               				productID: null
                        },
                    },
                }).then((res) => res.data.updateProduct)
            );


            await Promise.all(requestsAdd,requestsDelete);
            await this.getProductInfo()
            
            this.processing = false;
            const options={title:'',size:'sm'} 
            await this.$dialogs.alert("Product Saved", options).then(res=>{});

        },
    },

    async created() {
        this.product.companyProductsId = this.companyData.id;    
        await this.getProductInfo();
        await this.initMap();
        await this.getAttachments();

        this.checkCheckedInputs();
    },

};
</script>

<style>
    .rename-input{
        margin-left: 20px;
    }
</style>
